import React, { useEffect, useState } from "react"
import Editor from "@monaco-editor/react"
import styles from "./App.module.css"
import {
  FormControl,
  Select,
  TextField,
  MenuItem,
  Toolbar,
  Typography,
  AppBar,
  Button,
  Link,
} from "@material-ui/core"
import defaultCase from "./example-case"
import * as jsonata from "jsonata"
import { Builder } from "xml2js"
import * as jsonexport from "jsonexport"
import latinize from "latinize"

function App() {
  const [originalDataStructure, setOriginalDataStructure] = useState(
    JSON.stringify(defaultCase, null, 2)
  )
  const [mapping, setMapping] = useState(`{
    "items": aggregated.items.code.value,
    "documents": documents.documentType
}`)
  const [outputFormat, setOutputFormat] = useState("json")
  const [output, setOutput] = useState("")
  const [downloadLink, setDownloadLink] = useState("")
  const [csvSeperator, setCsvSeperator] = useState(",")
  const [csvIncludeHeaders, setCsvIncludeHeaders] = useState(true)
  const [removeAccents, setRemoveAccents] = useState(false)

  const calculateOutput = async (
    _mapping,
    _originalDataStructure,
    outputFormat
  ) => {
    const jsonataMapping = jsonata(_mapping)
    let newOutput = jsonataMapping.evaluate(JSON.parse(_originalDataStructure))
    console.log(newOutput)
    const builder = new Builder()
    switch (outputFormat) {
      case "txt":
        if (Array.isArray(newOutput)) {
          newOutput = newOutput.join("\n")
        } else {
          newOutput = newOutput.toString()
        }
        break
      case "csv":
        newOutput = await jsonexport(newOutput, {
          rowDelimiter: csvSeperator,
          includeHeaders: csvIncludeHeaders,
        })
        break
      case "xml":
        try {
          newOutput = builder.buildObject(newOutput)
        } catch (error) {
          newOutput = "Invalid: " + error
        }
        break
      case "json":
      default:
        newOutput = JSON.stringify(newOutput, null, 2)
        break
    }
    if (typeof newOutput === "string") {
      if (removeAccents) {
        newOutput = latinize(newOutput)
      }
      setOutput(newOutput)
      const data = new Blob([newOutput], {
        type: {
          json: "application/json",
          xml: "application/xml",
          csv: "text/csv",
          txt: "text/plain",
        }[outputFormat],
      })

      // this part avoids memory leaks
      if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink)

      // update the download link state
      setDownloadLink(window.URL.createObjectURL(data))
    } else {
      setOutput("Invalid mapping " + JSON.stringify(newOutput))
    }
  }

  useEffect(() => {
    calculateOutput(mapping, originalDataStructure, outputFormat)
  }, [
    mapping,
    originalDataStructure,
    outputFormat,
    csvIncludeHeaders,
    csvSeperator,
    removeAccents,
  ])

  return (
    <div className={styles.app}>
      <AppBar position='static'>
        <Toolbar className={styles.toolbar}>
          <Typography variant='h6'>Digicust Data Mapper</Typography>
          <Button
            component={Link}
            href='http://docs.jsonata.org/simple'
            target='_blank'
            color='inherit'>
            Mapping Documentation
          </Button>
        </Toolbar>
      </AppBar>
      <div className={styles.main}>
        <div className={styles.leftControl}>
          <div className={styles.editorWrapper}>
            <div className={styles.editorWrapper}>
              <div className={styles.actions}>
                <span>Original Data Structure</span>
              </div>
              <Editor
                path='originalDataStructure'
                className={styles.editor}
                value={originalDataStructure}
                onChange={(value, event) => {
                  setOriginalDataStructure(value)
                }}
                language='json'
              />
            </div>
          </div>
        </div>
        <div className={styles.rightControl}>
          <div className={styles.editorWrapper}>
            <div className={styles.actions}>
              <span>Mapping Definition</span>
            </div>
            <Editor
              path='mapping'
              className={styles.editor}
              value={mapping}
              onChange={(value, event) => {
                setMapping(value)
              }}
              language=''
            />
          </div>
          <div className={styles.editorWrapper}>
            <div className={styles.actions}>
              <span>Output</span>
              <span className={styles.actionButtons}>
                <FormControl>
                  <Select
                    labelId='demo-simple-select-filled-label'
                    id='demo-simple-select-filled'
                    value={removeAccents}
                    onChange={(event) => {
                      setRemoveAccents(event.target.value)
                    }}>
                    <MenuItem value={false}>Accents</MenuItem>
                    <MenuItem value={true}>No Accents</MenuItem>
                  </Select>
                </FormControl>
                {outputFormat === "csv" && (
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        value={csvSeperator}
                        onChange={(event) => {
                          setCsvSeperator(event.target.value)
                        }}></TextField>
                    </FormControl>
                    <FormControl>
                      <Select
                        labelId='demo-simple-select-filled-label'
                        id='demo-simple-select-filled'
                        value={csvIncludeHeaders}
                        onChange={(event) => {
                          setCsvIncludeHeaders(event.target.value)
                        }}>
                        <MenuItem value={true}>Include Headers</MenuItem>
                        <MenuItem value={false}>
                          Do not include Headers
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </React.Fragment>
                )}
                <FormControl>
                  <Select
                    labelId='demo-simple-select-filled-label'
                    id='demo-simple-select-filled'
                    value={outputFormat}
                    onChange={(event) => {
                      setOutputFormat(event.target.value)
                    }}>
                    <MenuItem value={"json"}>JSON</MenuItem>
                    <MenuItem value={"xml"}>XML</MenuItem>
                    <MenuItem value={"csv"}>CSV</MenuItem>
                    <MenuItem value={"txt"}>TXT</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  component={Link}
                  variant='contained'
                  href={downloadLink}
                  download={"data." + outputFormat}>
                  Download
                </Button>
              </span>
            </div>
            <Editor
              path='newDataStructure'
              className={styles.editor}
              value={output}
              onChange={(value, event) => {
                setOutput(value)
              }}
              language={outputFormat}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
